/*
Privacy Policy
}*/

.privacypolicy-container {
    width: 100%;
    max-width: 50%;
    max-height: 75%;
    margin-outside: 50%;
    height: 100vh;
    margin: 0 auto;

    display: block;
    align-items: center;
    justify-content: space-between;
}

.privacypolicy-container section.text {
    width: 100%;
    max-width: 100%;
    max-height: 75%;
    min-height: 75%;
    margin-outside: 50%;
    margin-right: 30px;
}

.privacypolicy-container section.text p {
    text-align: justify;
}


.privacypolicy-container section.text h1 {
    font-size: 32px;
    margin-bottom: 10px;
}
