@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

.outer-container {
}

html{
    height: 100%;
}

body {
    font: 14px 'Patrick Hand', cursive;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
}

input, button, textarea, pre {
        font: 400 18px 'Patrick Hand', cursive;
}

button {
    cursor: pointer;
}

form input {
    width: 100%;
    height: 60px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 0 24px;
}

form textarea {
    width: 100%;
    resize: vertical;
    min-height: 140px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 16px 24px;
    line-height: 24px;
}

.button {
    width: 100%;
    height: 60px;
    background: #3a8416;
    border: 0;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 700;
    margin-top: 16px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 60px;
    transition: 0.2s;
}

.button:hover {
    filter: brightness(120%);
}

.back-link {
    display: flex;
    align-items: center;
    margin-top: 40px;
    color: #42424d;
    font-size: 18px;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s;
}

.back-link:hover {
    opacity: 0.8;
}

.back-link svg {
    margin-right: 8px;
}

.carregando {
    position: fixed;
    top: 50%;
    left: 50%;
/* bring your own prefixes */
    transform: translate(-50%, -50%);
    display: grid;
}

.buttonloading {
    width: 100%;
    height: 60px;
    background: #c32361;
    border: 0;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 700;
    margin-top: 16px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 60px;
    transition: 0.8s;
    justify-self: center;
}

.buttonloading:hover {
    filter: brightness(150%);
    width: 70%;
    justify-self: center;
    text-align: center;
    border-radius: 50%;
}

.modal {
    position: fixed;
    top: 12px;
    padding: 24px;
    background-color: white;
    border: hidden;
    border-radius: 14px;
    z-index: 14;


    position: fixed;
    top: 50%;
    left: 50%;
/* bring your own prefixes */
    transform: translate(-50%, -50%);
    display: grid;
}

.modaloverlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 1000%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
}

.nowrap {
    white-space: nowrap;
}

